const _temp0 = require("../img/news/11042022/news01.jpg");
const _temp1 = require("../img/news/11042022/news02.jpg");
const _temp2 = require("../img/news/11042022/news03.jpg");
const _temp3 = require("../img/news/11042022/news04.jpg");
const _temp4 = require("../img/news/11042022/news05.jpg");
const _temp5 = require("../img/news/11042022/news06.jpg");
const _temp6 = require("../img/news/11042022/news07.jpg");
const _temp7 = require("../img/news/11042022/news08.jpg");
const _temp8 = require("../img/news/11042022/news09.jpg");
const _temp9 = require("../img/news/11042022/news10.jpg");
const _temp10 = require("../img/news/11042022/news11.jpg");
const _temp11 = require("../img/news/11042022/news12.jpg");
const _temp12 = require("../img/news/11042022/news13.jpg");
const _temp13 = require("../img/news/11042022/news14.jpg");
const _temp14 = require("../img/news/11042022/news15.jpg");
module.exports = {
  "news01": _temp0,
  "news02": _temp1,
  "news03": _temp2,
  "news04": _temp3,
  "news05": _temp4,
  "news06": _temp5,
  "news07": _temp6,
  "news08": _temp7,
  "news09": _temp8,
  "news10": _temp9,
  "news11": _temp10,
  "news12": _temp11,
  "news13": _temp12,
  "news14": _temp13,
  "news15": _temp14
}