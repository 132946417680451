export function debounce(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this, args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    }, wait);
    if (immediate && !timeout) func.apply(context, args);
  };
}

export function clearCanvas(context) {
  context.globalCompositeOperation = "source-over";
  context.setTransform(1, 0, 0, 1, 0, 0);
  context.clearRect(0, 0, context.canvas.width, context.canvas.height);
  context.beginPath();
}

export function getAsync(url, callback, isHtml = false) {
  var ajax = new XMLHttpRequest();
  ajax.open("GET", url, true);
  ajax.onload = function () {
    try {
      callback(isHtml ? ajax.responseText : JSON.parse(ajax.responseText));
    } catch (e) {
      console.error("invalid response");
      console.error(e);
    }
  };
  ajax.send();
}

export function postAsync(url, content, callback) {
  var ajax = new XMLHttpRequest();
  ajax.open("POST", url, true);
  ajax.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
  ajax.onload = function () {
    callback(ajax.responseText);
  };
  ajax.send(content);
}
